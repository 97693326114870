// src\pages\Home\components\MeetOurPartners\MeetOurPartners.module.scss

@import '../../../../styles/mdr';

.meetOurPartners {
  @include padding(50px 0 0);

  min-height: 150px;

  @include media-breakpoint-down(lg) {
    img {
      max-width: 100%;
    }
  }

  .meetOurPartnersTitle {
    @include font-size(14px);

    font-family: $graphik;
    font-weight: 500;
    letter-spacing: 1.75px;
    text-align: center;
    text-transform: uppercase;
    color: #cdcdcd;
  }

  .partnerItem {
    text-align: center;

    .partnerImage {
      img {
        max-height: 150px;
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }
  }
}
