// src\components\Footer\Footer.scss

@import '../../styles/mdr';

.footerWrap {
  @include margin-top(100px);
  @include gradient($branding1, #00ba7e, x);

  @include media-breakpoint-up(md) {
    min-height: 105px;
    padding: 0;
  }

  @include media-breakpoint-down(md) {
    @include padding(25px 0);

    text-align: center;
  }

  display: flex;
  align-items: center;

  .footer {
    .footerLogo {
      @include media-breakpoint-down(md) {
        @include margin-bottom(25px);
      }

      max-width: 63px;
    }

    a {
      @include font-size(14px);

      @include media-breakpoint-up(xl) {
        margin: 10px 20px 0 0;
      }

      @include media-breakpoint-up(lg) {
        margin: 10px 10px 0 0;
      }

      @include media-breakpoint-down(md) {
        margin: 0 0 10px;
      }

      color: $white;
      font-family: $graphik;
      font-weight: 500;
      padding: 0;

      &:hover {
        color: mix(white, $branding1, 75%);
      }

      &:visited {
        @extend a;
      }
    }

    .social-menu {
      @include media-breakpoint-down(md) {
        @include margin(15px 0);
      }

      a {
        @include font-size(12px);

        border: 1px solid $white;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        display: flex;
        justify-content: center;
        margin-top: 0;
        margin-right: 0;
        @include margin(0 5px);
        align-items: center;
        transition: 0.1s background-color ease-in-out, 0.1s border-color ease-in-out;

        &:hover {
          background-color: $branding1;
          border-color: darken($branding1, 5%);

          svg {
            path {
              fill: $white;
            }
          }
        }

        &:visited {
          @extend a;
        }
      }
    }

    .copyrightOuter {
      @include padding(0);

      @include media-breakpoint-down(lg) {
        @include padding-right(15px);
      }

      @include media-breakpoint-down(md) {
        @include padding-right(0);
      }
    }

    .copyright {
      @include font-size(14px);
      font-family: $graphik;
      color: $white;

      @include media-breakpoint-down(lg) {
        @include font-size(12px);
      }

      @include media-breakpoint-down(md) {
        @include font-size(14px);
      }
    }
  }
}
